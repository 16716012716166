/*
@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap');
*{
  font-family: "Gentium Book Plus", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

nav,footer,.MuiBottomNavigation-root{
  background-color: white !important;  
}
ul{
  background-color: transparent !important;
}


.MuiBottomNavigation-root{
  background-color: #202427 !important;  
}

  @media (min-width: 768px) {
    .desktop-space {
      display: block;
    }
  }
  @media (max-width: 767px) {
    .desktop-space {
      display: none;
    }
    .desktop-space2 {
      display: none;
    }
    .mobile-space {
      display: block;
    }
  }




.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

